<script setup lang="ts">
import { storeToRefs } from 'pinia'

import Loader from '~/components/shared/loader.vue'
import RatioContainer from '~/components/shared/ratioContainer.vue'
import VText from '~/components/ui/VText.vue'
import VideoSplitPlayer from '~/components/video/splitPlayer.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { getMediaLocaleFromLocale } from '~/utils/getMediaLocaleFromLocale'

import { Breakpoints } from '~/enums/breakpoints'

import type { RatioBindValues } from '~/components/shared/ratioContainer.vue'
import type { Locale } from '~/types/locale'

const route = useRoute()
const { locale } = useI18n()

const videoPath = ref('/welcomeVideos/Step')
const partCount = 5
const progress = ref(0)
const currentVideo = ref(0)

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const titleSize = computed(() =>
  SCREEN_WIDTH.value < Breakpoints.xs375
    ? '16'
    : SCREEN_WIDTH.value < Breakpoints.md
      ? '20'
      : '24',
)

const videoRatio = computed<RatioBindValues>(() => {
  if (SCREEN_WIDTH.value < Breakpoints.sm600) {
    return {
      xAspect: 101,
      yAspect: 180,
    }
  } else {
    return {
      xAspect: 253,
      yAspect: 180,
    }
  }
})

const videoSize = computed<'Mobile' | 'Desktop'>(() =>
  SCREEN_WIDTH.value < Breakpoints.sm600 ? 'Mobile' : 'Desktop',
)

const videoLang = computed<Uppercase<Locale>>(() => {
  return getMediaLocaleFromLocale(locale.value as Locale).toUpperCase()
})

const videos = computed<string[]>(() => {
  const videos: string[] = []
  let index = 0

  while (index < partCount) {
    videos.push(
      `${videoPath.value}${index++ + 1}_${videoSize.value}_${videoLang.value}`,
    )
  }

  return videos
})

const progressStyle = computed<Record<string, string>>(() => ({
  height: `${Math.round(progress.value * 100)}%`,
}))

function handleClick(index: number) {
  progress.value = 0
  currentVideo.value = index

  trackEvent(
    {
      category: 'Video presentation',
      action: 'Click on video title',
      videoNumber: index + 1,
    },
    route,
  )
}

function handleVideoUpdate(videoIndex: number) {
  currentVideo.value = videoIndex
}
</script>

<template>
  <div class="mainComponentWrapper">
    <div
      class="clickables"
      :class="{ mainParentContainer: SCREEN_WIDTH < Breakpoints.lg }"
    >
      <div
        class="tw-relative tw-mb-6 tw-grid tw-w-full tw-grid-cols-1 tw-gap-4 md:tw-mb-10 md:tw-gap-6"
      >
        <div
          v-for="(_, index) in videos"
          :key="index"
          class="tw-flex tw-w-full tw-cursor-pointer tw-flex-row"
          @click="handleClick(index)"
        >
          <div
            class="progressBar tw-relative tw-mr-4 tw-h-auto tw-w-1 tw-shrink-0 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-25"
          >
            <transition name="slow-fade" mode="out-in">
              <div
                v-if="currentVideo === index"
                class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-rounded-full tw-bg-orange-500 tw-transition-all tw-duration-150"
                :style="progressStyle"
              />
            </transition>
          </div>
          <div
            class="tw-flex tw-w-full tw-items-start tw-justify-start"
            :class="{ notCurrentVideo: index !== currentVideo }"
          >
            <VText
              :cfg="`sans/${titleSize}/bold`"
              color="orange-500"
              class="tw-mr-2 tw-block tw-w-5 tw-shrink-0"
            >
              {{ index + 1 }}.
            </VText>
            <VText
              :cfg="`sans/${titleSize}/bold`"
              color="black"
              class="tw-inline-block tw-w-full"
              :class="{ 'tw-text-center': index === currentVideo }"
            >
              {{ $t(`index.videoPresentation.videoText${index}`) }}
            </VText>
          </div>
        </div>
      </div>
    </div>
    <div class="videoWrapper tw-relative tw-w-full">
      <div :class="{ mainParentContainer: SCREEN_WIDTH < Breakpoints.lg }">
        <div class="videoWrapper">
          <RatioContainer v-bind="videoRatio">
            <div class="tw-shadow">
              <VideoSplitPlayer
                :model-value="currentVideo"
                :videos="videos"
                emit-progress
                :formats="['webm', 'mp4']"
                @progress="progress = $event"
                @update:model-value="handleVideoUpdate"
              />
            </div>
            <transition name="slow-fade">
              <div
                v-if="progress === 0"
                class="loaderContainer tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full"
              >
                <Loader />
              </div>
            </transition>
          </RatioContainer>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.localOrangeBg::before {
  --offset: 124px;
  @apply tw-bg-orange-500 tw-bg-opacity-5;
  content: '';
  position: absolute;
  top: var(--offset);
  z-index: -1;
  width: 100vw;
  height: calc(100% - var(--offset));

  @screen lg {
    --offset: 64px;
  }
}

.loaderContainer {
  z-index: -1;
}

.mainComponentWrapper {
  @apply tw-relative tw-grid tw-w-full tw-grid-cols-1 tw-gap-6;

  @screen lg {
    grid-template-columns: 8fr 4fr;
    padding-left: var(--grid-pad-adjust);
    padding-right: var(--grid-pad-adjust);
  }
}

.clickables {
  @screen lg {
    grid-column: 2;
    margin-top: 88px;
  }
}

.notCurrentVideo {
  @apply tw-opacity-25 tw-transition-opacity tw-duration-150 tw-ease-in-out;
}

.videoWrapper {
  @apply tw-mx-auto;
  width: var(--grid-3);

  @screen 600 {
    @apply tw-mx-0 tw-w-full;
  }

  @screen md {
    @apply tw-mx-auto;
    width: var(--grid-6);
  }

  @screen lg {
    @apply tw-w-full;
    grid-column: 1;
    grid-row: 1;
  }
}

.progressBar {
  @screen lg {
    height: 56px;
  }
}
</style>
